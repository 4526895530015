<script>
import Vue from "vue";
import EventBus from "@/event-bus";
import Repository from "@/app/repository/repository-factory";

const AttachmentRepository = Repository.get("AttachmentRepository");
/**
 * Sidebar attachments
 */
export default {
  props: {
    itemData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    downloadAttachment(attachmentId, filename) {
      return AttachmentRepository.download(attachmentId)
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/*",
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          link.remove();
          setTimeout(() => window.URL.revokeObjectURL(url), 200);
        })
        .catch((error) => {
          Vue.swal({
            icon: "error",
            position: "top",
            title: "Wystąpił błąd. Spróbuj jeszcze raz!",
            text: error.response.data.message,
            showConfirmButton: true,
          });
          console.log(error);
        });
    },
    deleteAttachment(attachmentId, filename) {
      Vue.swal({
        title: "Czy na pewno usunąć plik?",
        text: "Operacja jest nieodwracalna!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Tak, usuń!",
        cancelButtonText: "Anuluj",
        showLoaderOnConfirm: true,
        confirmButtonColor: "#61aa3d",
        cancelButtonColor: "#e2001a",
      }).then((result) => {
        if (result.value) {
          return AttachmentRepository.delete(attachmentId)
            .then((response) => {
              Vue.swal({
                icon: "success",
                toast: false,
                position: "top",
                title: "SUKCES!",
                text: "Załącznik został usunięty!",
                showConfirmButton: false,
                timer: 3500,
              });
              EventBus.$emit("refreshAttachmentsSidebarTable", attachmentId);
            })
            .catch((error) => {
              Vue.swal({
                icon: "error",
                position: "top",
                title: "Wystąpił błąd. Spróbuj jeszcze raz!",
                text: error.response.data.message,
                showConfirmButton: true,
              });
              console.log(error);
            });
        }
      });
    },
  },
};
</script>

<template>
  <div>
    <template v-if="itemData.attachments && itemData.attachments.length > 0">
      <div class="table-responsive mb-0">
        <table class="table table-sm">
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>#</th>
              <th>Nazwa pliku</th>
              <th>Pobierz</th>
              <th>Usuń</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody v-if="itemData.attachments">
            <tr v-for="(attachment, index) in itemData.attachments" :key="index">
              <template v-if="attachment.attachmentTypeOfObject">
                <td>&nbsp;</td>
                <td class="align-middle" scope="row"><span class="badge badge-pill badge-soft-success font-size-10">{{ index + 1 }}</span></td>
                <td class="align-middle small">{{ attachment.fileName }}<br /><span class="text-primary" v-if="attachment.attachmentTypeOfObject">{{ attachment.attachmentTypeOfObject.name }}</span></td>
                <td class="align-middle">
                  <button class="btn btn-sm btn-link m-0 p-0" @click="downloadAttachment(attachment.attachmentId, attachment.fileName)">
                    <i class="font-size-10 dripicons-download text-info" />
                  </button>
                </td>
                <td class="align-middle">
                  <button class="btn btn-sm btn-link m-0 p-0" @click="deleteAttachment(attachment.attachmentId, attachment.fileName)">
                    <i class="font-size-10 dripicons-trash text-danger" />
                  </button>
                </td>
                <td>&nbsp;</td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template v-else>
      <b-alert variant="success" show class="text-center">
        <strong>Brak załączników</strong> przypisanych do wniosku.
      </b-alert>
    </template>
  </div>
</template>